.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .6rem 5rem;
    margin: auto;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.418);
    width: 100%;

    /* To Fix Navbar :  */
    position: fixed;
    top: 0%;
    background-color: white;    
    z-index: 1000;  
    transition: top .4s;
}


/* Logo video CSS */
.content {
	width: 100%;
	position: sticky;
	top:0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Logo Video CSS Ends */

.navbar_subpart_1{
    width: 20%;
    /* border: 1px solid green; */
}
.navbar_subpart_1 a {
    display: flex;  
    /* border: 1px solid red; */
}
.navbar_subpart_1 a video{
    width: 80%;
    /* border: 1px solid red; */
}

.navbar_subpart_2 a {
    /* color: var(--blue); */
    color: var(--black);
    margin: 0 1rem;
    padding: .4rem .2rem;
    font-size: 1.1rem;

    display: inline-block;
    background-image: linear-gradient(var(--blue), var(--blue));
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.4s;
    background-position: 50% calc(100%);
}

.navbar_subpart_2 a:hover {
    color: rgb(100, 100, 100);
    /* border-bottom: 1px solid grey; */
    background-size: 100% 2px;
}


.navbar_subpart_3 a {
    /* border: 1px solid black; */
    display: block;
    background-color: var(--black);
    /* border: 2px solid var(--black); */
    color: var(--white);
    font-weight: 500;
    cursor: pointer;
    padding: .5rem 1rem;
    border-radius: .7rem;
    font-weight: bold;
}

.navbar_subpart_3 a:hover {
    color: white;
    /* background-color: white; */
    background-color: var(--blue);
    /* border: 2px solid var(--blue); */
    /* transform: scale(1.1);
    transition: all;
    -webkit-transition: transform .2s ease-in-out;
    -moz-transition:transform .2s ease-in-out;
    -ms-transition:transform .2s ease-in-out;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.734); */
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.734);
}
.lock_scroll{
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .navbar {
        padding: 0 1rem;
    }

    .navbar_subpart_1{
        /* border: 1px solid green; */
        width: 70%;
        padding: .6rem;
        margin: 0;
    }

    /* .navbar_subpart_1 a video{} */

    :root {
        --primary-color: rgba(39, 85, 85, 0.836);
        --overlay-color: rgba(25, 54, 78, 0.953);
        --menu-speed: 0.75s;
    }

    .menu-wrap {
        display: block;
        position: absolute;
        top: 4%;
        right: 1%;
        z-index: 1;
        /* border: 1px solid red; */
        width: 15%;
        /* height: 50%; */
    }

    .menu-wrap .toggler {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;
        width: 50px;
        height: 50px;
        opacity: 0;
    }

    .menu-wrap .hamburger {
        position: absolute;
        top: 80%;
        left: 0;
        z-index: 1;
        width: 2.5rem;
        height: 50px;
        /* padding: 1rem; */
        /* border: 1px solid grey; */
        border-radius: 6px;
        /* background: var(--primary-color); */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* Hamburger Line */
    .menu-wrap .hamburger>div {
        position: relative;
        flex: none;
        width: 90%;
        height: 1.5px;
        background: black;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;
    }

    /* Hamburger Lines - Top & Bottom */
    .menu-wrap .hamburger>div::before,
    .menu-wrap .hamburger>div::after {
        content: "";
        position: absolute;
        z-index: 1;
        top: -10px;
        width: 100%;
        height: .1rem;
        background: inherit;
        /* background: white; */
    }

    /* Moves Line Down */
    .menu-wrap .hamburger>div::after {
        top: 10px;
    }

    /* Toggler Animation */
    .menu-wrap .toggler:checked+.hamburger>div {
        transform: rotate(135deg);
    }

    /* Turns Lines Into X */
    .menu-wrap .toggler:checked+.hamburger>div:before,
    .menu-wrap .toggler:checked+.hamburger>div:after {
        top: 0;
        transform: rotate(90deg);
    }

    /* Rotate On Hover When Checked */
    .menu-wrap .toggler:checked:hover+.hamburger>div {
        transform: rotate(225deg);
    }

    /* Show Menu */
    .menu-wrap .toggler:checked~.menu {
        visibility: visible;
    }

    .menu-wrap .toggler:checked~.menu>div {
        transform: scale(1);
        transition-duration: var(--menu-speed);
        left: 0%;
    }

    .menu-wrap .toggler:checked~.menu>div>div {
        opacity: 1;
        transition: opacity 0.4s ease 0.4s;
    }

    .menu-wrap .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        visibility: hidden;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu-wrap .menu>div {
        /* background: var(--overlay-color); */
        background: var(--blue_navbar);
        /* border-radius: 50%; */
        width: 100%;
        height: 100vh;
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        left: 100%;
        /* transform: scale(0); */
        transition: all 0.4s ease;
        z-index: 1000 !important;
        position: fixed;
    }

    .menu-wrap .menu>div>div {
        text-align: center;
        max-width: 90vw;
        max-height: 100vh;
        opacity: 0;
        transition: opacity 0.4s ease;
    }

    .menu-wrap .menu>div>div>ul>li {
        list-style: none;
        color: #fff;
        font-size: 1.5rem;
        padding: 1rem;
    }

    .menu-wrap .menu>div>div>ul>li>a {
        /* color: rgb(255, 114, 89); */
        color: black;
        font-weight: bolder;
        font-size: 1.7rem;
        /* color: rgb(41, 40, 40); */
        padding: 5px 10px;
        /* border-radius: 4px; */
        text-decoration: none;
        transition: color 0.4s ease;
    }

    .menu-wrap .menu>div>div>ul>li>a.partner_with_us_button_hamburger{
        color: white;
        padding: .9rem 1.2rem;
        margin: 1rem auto;
        font-size: 1.6rem;
        width: 100%;
    }
}