.sellers_page_heading_section_background{
    background-size: auto;
    /* height: 10%; */
    /* border: 1px solid red; */
    max-width: 50%;
    background-size: cover;  
    background-position: center;   
    position: absolute; 
    z-index: 0;
    top:6%;    
    right: 5%;
}

.sellers_page_heading_section_box{
    margin: 8rem 5rem;
    margin-bottom: 0;
    /* border: 1px solid red; */
    height:max-content;
    width: 40%;
    font-size: 1.6rem;
}

/* .sellers_page_heading_section_box h1{
    font-family: VisbyRound_Heavy;
} */

.sellers_page_heading_1{
    color: var(--blue);
}

.sellers_page_heading_section_a{
    background-color: var(--black);
    color: var(--white);
    font-weight: 500;
    cursor: pointer;
    padding: 1rem 3rem;
    font-size: 1.5rem;
    border-radius: 1rem;
    font-weight: bold;
    margin: 5rem 0 6rem 5rem;
    display: inline-block;
}
.sellers_page_heading_section_a:hover{
    background-color: var(--blue);
    /* border: 1px solid var(--black); */
    font-weight: 600;
    /* transform: scale(1.1);
    transition: all;
    -webkit-transition: transform .2s ease-in-out;
    -moz-transition:transform .2s ease-in-out;
    -ms-transition:transform .2s ease-in-out;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.734); */
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.734);
}


.sellers_page_section_2_main_div{
    background-color: #DDF5FD;
    padding: 4rem 0;
}

.sellers_page_section_2_main_div_heading{
    text-align: left;
    font-size: 2.6rem;
    margin: 0 auto 4rem auto;
    padding: 0 0 1rem 0;
    width: 90%;
    /* font-family: VisbyRound_Heavy; */
    border-bottom: 2px solid grey;
}

.sellers_page_section_2_cards{
    width: 85%;
    /* height: 70px; */
    /* border: 1px solid red; */
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
}

.sellers_page_section_2_card{
    max-width: 22%;
    /* height: 100%; */
    /* background-color: rgb(255, 255, 255); */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sellers_page_section_2_card h1{
    font-size: 1.6rem;
    /* font-family: VisbyRound_Heavy; */
}

.sellers_page_section_2_card_image_div{
    width: 85%;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 10px solid var(--blue);
    margin: 1rem auto;
    box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.308);
}

.sellers_page_section_2_card_image_div img{
    max-height: 70%;
}

/* Section 3 */

.sellers_page_section_3_main_div{
    /* border: 1px solid red; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.sellers_page_section_3_main_div_heading{
    font-size: 2.6rem;
    /* font-family: VisbyRound_Heavy; */
    text-align: center;
    margin: 3rem auto;
    border-bottom: 2px solid grey;
    padding: 0 0 1rem 0;
    width: 90%;
}

.sellers_page_section_3_box{
    /* border: 1px solid black; */
    margin: 1rem auto;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Section 3 Ends */


.sellers_page_why_prefer_maqure_main_div{
    background-color: #DDF5FD;
    margin-bottom: 4rem;
}
.sellers_page_why_prefer_maqure_main_div h1{
    text-align: center;
    margin: 6rem auto 0 auto;
    font-size: 2.6rem;
    /* font-family: VisbyRound_Heavy; */
    border-bottom: 2px solid grey;
    padding: 4rem 0 1rem 0;
    width: 90%;
}

.sellers_page_why_prefer_maqure_box{
    /* border: 1px solid red; */
    width: 85%;
    margin: 0 auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sellers_page_why_prefer_maqure_box h1{
    font-size: 2.6rem;
}

.sellers_page_why_prefer_maqure_box_heading_maqure{
    color: var(--blue);
    text-transform: uppercase;
    /* font-family: VisbyRound_Heavy; */
}

.sellers_page_why_prefer_maqure_box_2{
    /* border: 1px solid green; */
    margin: 1rem 0;
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sellers_page_why_prefer_maqure_box_2_subbox{
    /* border: 1px solid black; */
    background-color: var(--blue);
    /* background-color: white; */
    color: black;
    width: 45%;;
    display: flex;
    margin: 1.6rem 0;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 1px 1px 18px rgba(0, 0, 0, 0.253);
}

.sellers_page_why_prefer_maqure_box_2_subbox_img{
    width: 8%;
    /* border: 1px solid blue; */
    margin: .6rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sellers_page_why_prefer_maqure_box_2_subbox img{
    /* width: 100%; */
    /* border: 1px solid red; */
    padding: .2rem;
}

.sellers_page_why_prefer_maqure_box_2_subbox p{
    /* border: 1px solid red; */
    /* font-family: VisbyRound_DemiBold; */
    display: flex;
    align-items: center;
    width: calc(100% - 8%);
    /* color: black; */
    color: white;
    font-size: 1.3rem;
}


/* FAQ */

.sellers_page_faq_main_box{
    width: 85%;
    margin: 5rem auto;
    /* border: 1px solid black; */
    margin-bottom: -7rem;
    display: flex;
}

.sellers_page_faq_box{
    width: 50%;
    /* border: 1px solid blue; */
}

.sellers_page_faq_box_2{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    max-width: 90%;
}
.sellers_page_faq_box img{
    /* padding: 3rem; */
    /* max-width: 90%; */
    max-height: 90%;
}

.sellers_page_faq_box_heading{
    /* border: 1px solid green; */
    /* margin: 3rem 0; */
    margin-bottom: 3rem;
    /* text-align: center; */
    font-size: 1.2rem;
}
.sellers_page_faq_box_1{
    /* border: 1px solid red; */
    padding: 2rem;
}




@media only screen and (max-width:600px){
    .sellers_page_heading_section_box{
        /* border: 1px solid red; */
        margin: 7rem auto;
        width: 85%;
        text-align: center;
    }

    .sellers_page_heading_section_a{
        margin: 0rem auto 6rem auto;
        text-align: center;
        /* border: 1px solid red; */
        display: table;
    }

    .sellers_page_section_2_main_div{
        padding-bottom: 2rem;
        margin-top: 5rem;
        background-color: white;
    }

    .sellers_page_section_2_main_div_heading{
        text-align: center;
    }

    .sellers_page_section_2_cards{
        flex-direction: column;
        align-items: center;
    }

    .sellers_page_section_2_card{
        max-width: 90%;
        margin: 1rem 0;
    }

    .sellers_page_section_2_card_image_div img {
        padding: 2.2rem;
    }

    .sellers_page_why_prefer_maqure_box_2{
        flex-direction: column;
        align-items: center;
    }

    .sellers_page_why_prefer_maqure_box{
        width: 90%;
    }

    .sellers_page_why_prefer_maqure_box_2_subbox{
        width: 100%;
    }

    .sellers_page_why_prefer_maqure_box_2_subbox img{
        padding: 0;
    }

    .sellers_page_why_prefer_maqure_box_2_subbox_img{
        width: 10%;
    }

    .sellers_page_faq_main_box{
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
    }

    .sellers_page_faq_box{
        width: 100%;
        margin-bottom: 3rem;
    }

    .sellers_page_faq_box img{
        width: 90%;
    }

    .sellers_page_faq_box_heading h1{
        text-align: center;
    }

    .sellers_page_faq_box_2{
        margin-bottom: 0;
    }
}